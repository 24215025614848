/**
 * Map of root domains to Tenant Config API Ids
 * Each pair below is in: "$basedomain": "$tenantConfigId"
 *
 *
 * TODO: rework this into a process that does not require fetching
 *       Tenant Configs on the front-end, see PREP-337
 */
/* eslint-disable */

export default {
  "k95tulsa": "kwen-fm",
  "wsbradio": "wsb-am",
  "wsbtv": "wsb-tv",
  "y100fm": "kcyy-fm",
  "houstonseagle": "kglk-fm",
  "kiro7": "kiro-tv",
  "kissrocks": "kiss-fm",
  "1033theeagle": "kjsr-fm",
  "93qcountry": "kkbq-fm",
  "kkyx": "kkyx-fm",
  "fox23": "koki-tv",
  "business860": "kono-am",
  "kono1011": "kono-fm",
  "mix965tulsa": "krav-fm",
  "krmg": "krmg-fm",
  "hits1053sanantonio": "ksmg-fm",
  "countrylegends971": "ktht-fm",
  "eaglesanantonio": "ktkx-fm",
  "1057thebone": "kwen-hd2",
  "kiss104fm": "walr-fm",
  "wape": "wape-fm",
  "wbab": "wbab-fm",
  "wbli": "wbli-fm",
  "star945": "wcfb-fm",
  "1073soloexitos": "wcfb-hd2",
  "hits965orlando": "woex-fm",
  "espn580orlando": "wdbo-am",
  "news965": "wdbo-fm",
  "wdbo": "wdbo-fm",
  "wduv": "wduv-fm",
  "99jamzmiami": "wedr-fm",
  "easy1029": "wezi-fm",
  "easy93": "wfez-fm",
  "hits973": "wflc-fm",
  "fox30jax": "wfox-tv",
  "wftv": "wf-tv",
  "boston25news": "wfxt-tv",
  "wgauradio": "wgau-am",
  "magic1021": "wgmg-fm",
  "fox13memphis": "whbq-tv",
  "whio": "whio-tv",
  "k99online": "whko-fm",
  "theboneonline": "whpt-fm",
  "hot105fm": "whqt-fm",
  "actionnewsjax": "wjax-tv",
  "969theeagle": "wjgl-fm",
  "power1061": "wjgl-hd2",
  "wmmo": "wmmo-fm",
  "yourgeorgiacountry": "wngc-fm",
  "espn690": "wokv-am",
  "wokv": "wokv-fm",
  "hot1065fm": "wokv-hd2",
  "1015vibe": "wpoi-fm",
  "powerathens": "wpup-fm",
  "wpxi": "wpxi-tv",
  "powerorlando": "wcfb-hd2",
  "960theref": "wrfc-am",
  "b985": "wsb-fm",
  "wsoctv": "wsoc-tv",
  "971theriver": "wsrv-fm",
  "97xonline": "wsun-fm",
  "k923orlando": "wwka-fm",
  "mymagic949": "wwrm-fm",
  "1073theeagle": "wxgl-fm",
  "1037chuckfm": "wxkt-fm",
  "x995jax": "wxxj-fm",
  "eagledayton": "wzlr-fm"
};
