import React from "react";
import { SignUp } from "aws-amplify-react";
import { API } from "aws-amplify";
import { withStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  FormGroup,
  Link,
  Typography
} from "@material-ui/core";
import SnackbarAlert from "../components/utils/SnackbarAlert";
import AuthTabs from "./AuthTabs";
import ExtendedTextField from "../extended-material-ui/TextField";
import NicknameOptions from "../components/Account/NicknameOptions";
import {
  isValidZipCode,
  isValidNickName,
  checkUniqueNickname,
  isValidDate
} from "../utility/userProfileValidators";

/* Authenticator components can be extended to allow UI customization
 * and in some cases, minor functionality and flow customization.
 *
 * The main method that needs to be overridden for most UI customization is showComponent()
 */

// styles
const styles = theme => ({
  mcButton: {
    ...theme.typography.mcButton
  },
  mcLink: {
    ...theme.mcLink
  }
});

class ExtendedSignUpThemed extends SignUp {
  formFieldHelperText = {
    nickname: "Display name must have at least 4 letters/numbers",
    uniqueNickname: "Display name is not unique. Please try again",
    email: "Please enter a valid email",
    birthdate: "Birth date is either missing or has an invalid format",
    "custom:zip_code": "5-digit zip code is required",
    "custom:policyConsent":
      "You must agree to our Visitor Agreement and Privacy Policy"
  };

  constructor(props) {
    super(props);
    this._validAuthStates = ["signUp"];
    this.state = {
      extSignUpThemedSignUpError: false,
      extSignUpThemedSignUpErrorMessage: "",
      extSignUpThemedSignUpErrorMessageCount: 0,
      nickname: ""
    };
    this.nicknameOptions = [];
  }

  validEmailCheck = async () => {
    const email = this.inputs["email"];
    if (!email) return false;
    try {
      const response = await API.post("email", "/simple-verification", {
        queryStringParameters: { email }
      });
      return response.isValid;
    } catch (e) {
      console.error("Error validating email", e);
    }
  };

  validateInputData = async () => {
    let response = {
      status: "", // OK, ERROR
      errorMessages: []
    };

    if (!isValidZipCode(this.inputs["zip_code"])) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText["custom:zip_code"]);
    }

    if (!isValidDate(this.inputs.birthdate)) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.birthdate);
    }

    if (!this.inputs.policyConsent || isNaN(this.inputs.policyConsent)) {
      response.status = "ERROR";
      response.errorMessages.push(
        this.formFieldHelperText["custom:policyConsent"]
      );
    }

    let _checkUniqueNickname = () => Promise.resolve();
    if (!isValidNickName(this.inputs["nickname"])) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.nickname);
    } else {
      _checkUniqueNickname = checkUniqueNickname;
    }

    // Resolves enpoint requests concurrently
    const endpointValidations = await Promise.all([
      _checkUniqueNickname(this.inputs["nickname"], this.inputs["email"]),
      this.validEmailCheck()
    ]);

    if (endpointValidations[0]) {
      const { isUnique, suggestions = [] } = endpointValidations[0];
      if (!isUnique) {
        response.status = "ERROR";
        response.errorMessages.push(this.formFieldHelperText.uniqueNickname);
        this.nicknameOptions = suggestions;
      } else {
        this.nicknameOptions = [];
      }
    }

    const validEmail = endpointValidations[1];
    if (validEmail === false) {
      response.status = "ERROR";
      response.errorMessages.push(this.formFieldHelperText.email);
    }

    if (response.status === "" && response.errorMessages.length === 0) {
      response.status = "OK";
    }
    return response;
  };

  hideAlert = () => {
    this.setState({
      extSignUpThemedSignUpError: false,
      extSignUpThemedSignUpErrorMessage: "",
      extSignUpThemedSignUpErrorMessageCount: 0
    });
  };

  signUp = async () => {
    this.inputs = this.inputs || {};
    this.inputs["signup_tenant"] = this.props.site;

    Object.keys(this.inputs).forEach(input => {
      const inputValue = this.inputs[input];
      if (inputValue && typeof inputValue === "string") {
        this.inputs[input] = inputValue.trim();
      }
    });

    const validationResponse = await this.validateInputData();
    if (
      validationResponse.status !== "OK" &&
      validationResponse.errorMessages.length > 0
    ) {
      this.setState({
        extSignUpThemedSignUpError: true,
        extSignUpThemedSignUpErrorMessage: validationResponse.errorMessages.join(
          " -- "
        ),
        extSignUpThemedSignUpErrorMessageCount:
          validationResponse.errorMessages.length
      });
    } else {
      delete this.inputs.checkedValue;
      super.signUp();
    }
  };

  signUpWithEnter = async e => {
    if (e.key === "Enter") {
      e.preventDefault();
      await this.signUp();
    }
  };

  handleEmailInputChangeAndUsername = e => {
    this.handleInputChange(e);
    this.inputs = this.inputs || {};
    const { value } = e.target;
    this.inputs["username"] = value;
  };

  handleNicknameChange = e => {
    this.handleInputChange(e);
    const { value } = e.target;
    this.setState({ nickname: value });
  };

  handleNicknameOptionChange = e => {
    this.inputs = this.inputs || {};
    const { value } = e.target;
    this.inputs["nickname"] = value;
    this.setState({ nickname: value });
  };

  handlePolicyConsentChange = e => {
    this.inputs = this.inputs || {};
    const { checked } = e.target;
    if (checked === true) {
      this.inputs["policyConsent"] = Date.now().toString();
    } else {
      this.inputs["policyConsent"] = "";
    }
  };

  showComponent() {
    this.signUpFields = this.props.signUpConfig.signUpFields;
    const { privacyPolicy, visitorAgreement } = this.props;

    return (
      <AuthTabs
        authState={this.props.authState}
        onTabChange={tabValue => super.changeState(tabValue)}
      >
        {this.props.authState === "signUp" && (
          <>
            <Box mb={2}>
              <p>All fields are required.</p>
              <FormGroup>
                <form>
                  <ExtendedTextField
                    id="email"
                    key="email"
                    name="email"
                    type="text"
                    label="Email Address"
                    onChange={this.handleEmailInputChangeAndUsername}
                    onKeyPress={this.signUpWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="username"
                    key="username"
                    name="username"
                    type="text"
                    label="User Name"
                    onChange={this.handleInputChange}
                    style={{ display: "none" }}
                    required
                  />
                  <ExtendedTextField
                    id="password"
                    key="password"
                    name="password"
                    type="password"
                    label="Password"
                    onChange={this.handleInputChange}
                    onKeyPress={this.signUpWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="nickname"
                    key="nickname"
                    name="nickname"
                    type="text"
                    label="Display Name"
                    onChange={this.handleNicknameChange}
                    onKeyPress={this.signUpWithEnter}
                    value={this.state.nickname}
                    color="secondary"
                    required
                  />
                  {this.nicknameOptions.length > 0 && (
                    <NicknameOptions
                      handleChange={this.handleNicknameOptionChange}
                      options={this.nicknameOptions}
                    />
                  )}
                  <ExtendedTextField
                    id="given_name"
                    key="given_name"
                    name="given_name"
                    type="text"
                    label="First Name"
                    onChange={this.handleInputChange}
                    onKeyPress={this.signUpWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="family_name"
                    key="family_name"
                    name="family_name"
                    type="text"
                    label="Last Name"
                    onChange={this.handleInputChange}
                    onKeyPress={this.signUpWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="birthdate"
                    key="birthdate"
                    name="birthdate"
                    type="date"
                    label="Birth Date"
                    placeholder="yyyy-mm-dd" /* for browsers that don't support the date type */
                    InputLabelProps={{ shrink: true }}
                    onChange={this.handleInputChange}
                    onKeyPress={this.signUpWithEnter}
                    color="secondary"
                    required
                  />
                  <ExtendedTextField
                    id="zip_code"
                    key="zip_code"
                    name="zip_code"
                    type="text"
                    label="Zip Code (5 digits)"
                    inputProps={{ maxLength: 10 }}
                    onChange={this.handleInputChange}
                    onKeyPress={this.signUpWithEnter}
                    color="secondary"
                    required
                  />
                  <Box my={1}>
                    <Divider />
                  </Box>
                  <FormControlLabel
                    control={
                      <Checkbox
                        id="policyConsent"
                        key="policyConsent"
                        name="policyConsent"
                        onChange={this.handlePolicyConsentChange}
                        required
                      />
                    }
                    label={
                      <Typography variant="subtitle2">
                        I have read and agree to the&nbsp;
                        <Link
                          className={this.props.classes.mcLink}
                          href={visitorAgreement}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Visitor Agreement
                        </Link>
                        {" and "}
                        <Link
                          className={this.props.classes.mcLink}
                          href={privacyPolicy}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Privacy Policy
                        </Link>
                        &nbsp;terms
                      </Typography>
                    }
                  />
                  <Box my={2}>
                    <Button
                      className={this.props.classes.mcButton}
                      size="large"
                      variant="contained"
                      fullWidth
                      onClick={this.signUp}
                    >
                      Create Your Profile
                    </Button>
                  </Box>
                </form>
              </FormGroup>
              <SnackbarAlert
                show={this.state.extSignUpThemedSignUpError}
                hide={this.hideAlert}
                duration={
                  3000 * this.state.extSignUpThemedSignUpErrorMessageCount
                }
                severity={"error"}
                message={this.state.extSignUpThemedSignUpErrorMessage}
              />
            </Box>
          </>
        )}
      </AuthTabs>
    );
  }
}

export const ExtendedSignUp = withStyles(styles, { withTheme: true })(
  ExtendedSignUpThemed
);
